<script setup lang="ts">
import CustomerOrder from "@/components/dashboard/CustomerOrder.vue";
import {
  fetchIncompleteDriverOrder,
  fetchUpcomingDriverOrder,
} from "@/services/order.service";
import { useOrderStore } from "@/store/orderStore";
import { onMounted, ref, watch } from "vue";
import router from "@/router";
import $toast from "@/utils/toast";
import { TYPE } from "vue-toastification";
import { useUserStore } from "@/store/userStore";
import NoOrder from "@/components/dashboard/NoOrder.vue";
import Refresh from "@/assets/refresh-cw.svg?component";
import { Task, Task_State_Enum } from "@/sdk";
import { updateTaskState } from "@/services/task.service";
import dayjs from "dayjs";

const orderStore = useOrderStore();
const userStore = useUserStore();

const showCTAProgress = ref(false);
const isChecked = ref(false);
const startOrderDispensingLabel = ref("Select order");
const selectedOrder = ref<Task | null>(null);
const isSpinning = ref(false);
const isLoading = ref(true);

const startTime = dayjs(userStore.getShiftSchedule()?.start_time).format();
const endTime = dayjs(userStore.getShiftSchedule()?.end_time)
  .add(6, "hour")
  .format();

const selectAssetBeforeDispensing = (order: Task) => {
  selectedOrder.value = order;
  startOrderDispensingLabel.value = "Start order";
};

const goToChooseAssets = async () => {
  try {
    if (!selectedOrder.value) {
      $toast("Please select an order", TYPE.INFO);
      return;
    }

    showCTAProgress.value = true;
    orderStore.setCurrentOrder(selectedOrder.value);
    startOrderDispensingLabel.value = "Starting...";

    if (selectedOrder.value.state === Task_State_Enum.Assigned) {
      await updateTaskState(
        selectedOrder.value.id,
        Task_State_Enum.InTransit
      );
    }

    const nextRoute = orderStore.getHSECompleted
      ? `/choose-assets/${selectedOrder.value.id}`
      : `/healthchecks/${selectedOrder.value.id}`;
    await router.push(nextRoute);
  } catch (error) {
    console.error("Failed to start order:", error);
    $toast("Failed to start order", TYPE.ERROR);
  } finally {
    showCTAProgress.value = false;
  }
};

const refetchOrdersManually = async () => {
  try {
    isSpinning.value = true;
    isChecked.value = true;
    await Promise.all([
      fetchIncompleteDriverOrder(),
      fetchUpcomingDriverOrder(),
    ]);
    $toast("Orders refreshed", TYPE.SUCCESS);
  } catch (error) {
    console.error("Error refreshing orders:", error);
    $toast("Failed to refresh orders", TYPE.ERROR);
  } finally {
    isSpinning.value = false;
  }
};

onMounted(async () => {
  try {
    isLoading.value = true;
    if (!userStore.getShiftSchedule()) {
      return router.replace("/");
    }

    await Promise.all([
      fetchIncompleteDriverOrder(),
      fetchUpcomingDriverOrder(startTime, endTime),
    ]);

    const interval = setInterval(async () => {
      await Promise.all([
        fetchIncompleteDriverOrder(),
        fetchUpcomingDriverOrder(startTime, endTime),
      ]);
    }, 300000);

    return () => clearInterval(interval);
  } catch (error) {
    console.error("Error during initialization:", error);
    $toast("Failed to load orders", TYPE.ERROR);
  } finally {
    isLoading.value = false;
  }
});

watch(isChecked, async () => {
  try {
    showCTAProgress.value = true;
    if (!isChecked.value) {
      await fetchUpcomingDriverOrder(startTime, endTime);
      $toast("Today's orders fetched", TYPE.SUCCESS);
    } else {
      await Promise.all([
        fetchIncompleteDriverOrder(),
        fetchUpcomingDriverOrder(),
      ]);
      $toast("All orders fetched", TYPE.SUCCESS);
    }
  } catch (error) {
    console.error("Error watching checkbox state:", error);
    $toast("Failed to fetch orders", TYPE.ERROR);
  } finally {
    showCTAProgress.value = false;
  }
});
</script>

<template>
  <div class="flex flex-col h-screen">
    <!-- Header -->
    <div class="p-4 bg-white shadow-sm">
      <label class="flex items-center space-x-2 text-sm text-gray-700 font-medium">
        <span v-if="!isChecked">Show today's orders</span>
        <span v-else>Show all orders</span>
        <input
          type="checkbox"
          v-model="isChecked"
          :disabled="showCTAProgress || isLoading"
          class="toggle toggle-success"
        />
      </label>
    </div>

    <!-- Main Content -->
    <div class="flex-1 flex flex-col relative">
      <!-- Orders List -->
      <div class="flex-1 overflow-y-auto px-4 py-2 space-y-3">
        <!-- Show skeletons when loading -->
        <template v-if="isLoading">
          <div v-for="n in 3" :key="n" class="card bg-white border shadow-sm">
            <div class="card-body p-4">
              <CustomerOrder :isLoading="true" />
            </div>
          </div>
        </template>

        <!-- Show actual content when not loading -->
        <template v-else>
          <!-- Current incomplete order -->
          <div v-if="orderStore.currentOrderType === 'incomplete' && orderStore.getCurrentOrder">
            <div
              class="card bg-yellow-50 border transition-all duration-200"
              :class="[
                selectedOrder?.id === orderStore.getCurrentOrder.id
                  ? 'border-2 border-yellow-400 shadow-lg'
                  : 'border-yellow-200 shadow-sm'
              ]"
              @click="selectAssetBeforeDispensing(orderStore.getCurrentOrder)"
            >
              <div class="card-body p-4">
                <CustomerOrder 
                  :order="orderStore.getCurrentOrder"
                  :isLoading="false"
                />
              </div>
            </div>
          </div>

          <!-- Incomplete orders -->
          <div
            v-for="order in orderStore.getIncompleteOrders"
            :key="order.id"
            class="card bg-white border transition-all duration-200"
            :class="[
              selectedOrder?.id === order.id
                ? 'border-2 border-yellow-400 shadow-lg'
                : 'border-gray-200 shadow-sm'
            ]"
            @click="selectAssetBeforeDispensing(order)"
          >
            <div class="card-body p-4">
              <CustomerOrder 
                :order="order"
                :isLoading="false"
              />
            </div>
          </div>

          <!-- All orders -->
          <div
            v-for="order in orderStore.getAllOrders"
            :key="order.id"
            class="card bg-white border transition-all duration-200"
            :class="[
              selectedOrder?.id === order.id
                ? 'border-2 border-yellow-400 shadow-lg'
                : 'border-gray-200 shadow-sm'
            ]"
            @click="selectAssetBeforeDispensing(order)"
          >
            <div class="card-body p-4">
              <CustomerOrder 
                :order="order"
                :isLoading="false"
              />
            </div>
          </div>

          <!-- Spacer for fixed button -->
          <div class="h-20"></div>
        </template>
      </div>

      <!-- No Orders State -->
      <div 
        v-if="!isLoading && !orderStore.getAllOrders.length && !orderStore.getCurrentOrder" 
        class="flex-1 flex flex-col items-center justify-center p-4"
      >
        <NoOrder />
        <button
          @click="refetchOrdersManually"
          class="mt-4 btn bg-orange-600 text-white hover:bg-orange-700 focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
          :disabled="isSpinning"
        >
          <span :class="{ 'animate-spin': isSpinning }">
            <Refresh />
          </span>
          <span class="ml-2">Refresh Orders</span>
        </button>
      </div>

      <!-- Fixed Button Container -->
      <div 
        v-if="!isLoading && (orderStore.getAllOrders.length > 0 || orderStore.getCurrentOrder)"
        class="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200 shadow-lg"
      >
        <button
          @click="goToChooseAssets"
          class="w-full btn bg-emerald-600 text-white hover:bg-emerald-700 focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
          :disabled="showCTAProgress || !selectedOrder"
        >
          <span
            v-if="showCTAProgress"
            class="loading loading-spinner loading-sm mr-2"
          ></span>
          {{ startOrderDispensingLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #666;
}
</style>