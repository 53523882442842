import { GraphQLClient } from "graphql-request";
import { getSdk, SdkFunctionWrapper } from "@/sdk";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "@/firebase";
import { useUserStore } from "@/store/userStore";
import { getRefreshToken } from "./auth.service";

const auth = getAuth(app);

export const getToken = async () => {
	return new Promise((resolve) => {
		onAuthStateChanged(auth, async (user) => {
			if (user) {
				const userStore = useUserStore();
				const userToken = await user.getIdToken(true);
				userStore.setAuthToken(userToken);
				resolve(true);
			} else resolve(false);
		});
	});
};
const withTokenRefresh: SdkFunctionWrapper = async <T>(
	action: (requestHeaders?: Record<string, string>) => Promise<T>,
): Promise<T> => {
	const userStore = useUserStore();
	const executeAction = (token: string) =>
		action({ Authorization: `Bearer ${token}` });

	try {
		return await executeAction(userStore.getAuthToken());
	} catch (error) {
		if (!isJWTExpiredError(error) || !auth.currentUser) {
			throw error;
		}

		const newToken = await getRefreshToken(
			auth.currentUser.uid,
		).catch((refreshError) => {
			console.error("Token refresh failed:", refreshError);
			throw refreshError;
		});

		userStore.setAuthToken(newToken);
		return executeAction(newToken);
	}
};

// Helper function to type-check JWT expiration errors
const isJWTExpiredError = (error: unknown): boolean => {
	return (
		error instanceof Error && error.message?.includes("JWTExpired")
	);
};

const client = (async () => {
	try {
		await getToken();
		const userStore = useUserStore();
		const graphQLClient = new GraphQLClient(
			import.meta.env.VITE_GRAPHQL_SCHEMA_PATH,
			{
				headers: () => ({
					Authorization: "Bearer " + userStore.getAuthToken(),
				}),
			},
		);
		return getSdk(graphQLClient, withTokenRefresh);
	} catch (error) {
		throw new Error("Error initializing client", { cause: error });
	}
})();

export default client;
