import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "@/firebase";
import { useUserStore } from "@/store/userStore";
import { FirebaseError } from "firebase/app";
import { getToken } from "./client";
import axios from "axios";

let tokenRefreshTimer: NodeJS.Timeout | null = null;
const TOKEN_REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds

export const startTokenRefreshTimer = () => {
	tokenRefreshTimer = setInterval(async () => {
		try {
			await getToken();
		} catch (error) {
			console.error("Error refreshing token:", error);
		}
	}, TOKEN_REFRESH_INTERVAL);
};

export const getRefreshToken = async (uid: string) => {
	return new Promise<string>((resolve) => {
		try {
			setTimeout(async () => {
				const result = await axios.get(import.meta.env.VITE_REFRESH_TOKEN_URL,
					{
						params: {
							uid: `${uid}`,
						},
					},
				);
				resolve(result?.data?.user[0]?.id);
			}, 3000);
		} catch (error) {
			console.error(
				"Error occured while fetching refresh token",
				error,
			);
			throw error;
		}
	});
};


export const login = async (email: string, password: string) => {
	try {
		const userStore = useUserStore();
		const userCredentials = await signInWithEmailAndPassword(
			auth,
			email,
			password,
		);
		const allowedClaims = (
			(await userCredentials.user.getIdTokenResult()).claims[
				"https://hasura.io/jwt/claims"
			] as { "x-hasura-default-role": string }
		)["x-hasura-default-role"] as string;

		if (allowedClaims === "driver") {
			userStore.setUser(userCredentials.user);
			await getRefreshToken(userCredentials.user.uid);
		} else {
			throw new Error("You are not registered as a driver");
		}
	} catch (error) {
		let errorMessage = "Error logging in";
		if ((error as FirebaseError).code === "auth/user-not-found") {
			errorMessage = "User not found";
		} else if (
			(error as FirebaseError).code === "auth/wrong-password"
		) {
			errorMessage = "Wrong password";
		} else if (
			(error as FirebaseError).code === "auth/invalid-email"
		) {
			errorMessage = "Invalid email";
		}
		throw new Error(errorMessage);
	}
};

export const logout = async () => {
	localStorage.clear();
	await signOut(auth);
	if (tokenRefreshTimer) {
		clearInterval(tokenRefreshTimer);
		tokenRefreshTimer = null;
	}
	window.location.replace("/");
};


