<script setup lang="ts">
//dependencies
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

//store
import { useOrderStore, useLoaderStore } from "@/store";
import {
    markOrderCompleted,
    addTransactionLogs,
    createDeliveryInvoice,
    fuelTransKeyUpdate
} from "@/services/order.service";
import OrderCard from "@/components/steps/OrderCard.vue";
import $toast from "@/utils/toast";
import { TYPE } from "vue-toastification";

const router = useRouter();
const orderStore = useOrderStore();
const loaderStore = useLoaderStore();
const rate = ref<number>(0);
const disableButton = ref<boolean>(false);
const selectedDeliveryType = ref<'fueltrans' | 'regular' | null>(null);
const isLoading = ref<boolean>(false);

const invoiceCreated = ref<any>();
const transactionLogCreated = ref<any>();
const orderCompletedMarked = ref<any>();
const filteredTankProductVarId = ref<any>();

const totalAssetsFilled = () => {
  return (
    orderStore.getCurrentOrderAssets?.filter(
      (asset) => asset?.quantity_dispensed !== null
    ).length || 0
  );
};

const handleDeliveryCompletion = async () => {
    if (!selectedDeliveryType.value) {
        $toast("Please select a delivery type", TYPE.WARNING);
        return;
    }

    const assetsDispensedCount = totalAssetsFilled();

    if (selectedDeliveryType.value === 'fueltrans' && assetsDispensedCount > 0) {
        $toast("Cannot complete order with fuel trans when fuel has been dispensed", TYPE.ERROR);
        return;
    }

    disableButton.value = true;
    isLoading.value = true;
    loaderStore.toggleLoader({type: 'common', state: true});

    try {
        if (selectedDeliveryType.value === 'fueltrans') {
            await markOrderDeliverdWithFuelTrans();
        } else {
            await nextStep();
        }
    } catch (error) {
        console.error("Error in completing order:", error);
        $toast("Error in completing order. Please try again.", TYPE.ERROR);
    } finally {
        loaderStore.toggleLoader({type: 'common', state: false});
        disableButton.value = false;
        isLoading.value = false;
    }
};

const nextStep = async () => {
    try {
        if (
            !invoiceCreated.value &&
            localStorage.getItem(
                "invoice_created/" + orderStore.getCurrentOrder.id,
            ) !== "true"
        ) {
            await createInvoiceOnly();
        }

        filteredTankProductVarId.value =
            orderStore?.getDriverVehicleDetails?.vehicle_tank_types
                .filter((tank: any) => tank.tank_type.slug === "browser-tank")
                .map(
                    (tank: any) =>
                        tank.vehicle_tank_type_product_variations[0]
                            ?.product_variation?.id,
                );

        if (
            !transactionLogCreated.value &&
            localStorage.getItem(
                "transaction_logs_added/" + orderStore.getCurrentOrder.id,
            ) !== "true"
        ) {
            await addTransactionLogsOnly();
        }

        await markOrderCompletedOnly();
        orderStore.resetStore();
        router.replace("/dashboard");
    } catch (error) {
        $toast("Error completing order", TYPE.ERROR);
        throw new Error("Error completing order", { cause: error });
    }
};

async function createInvoiceOnly() {
    try {
        invoiceCreated.value = await createDeliveryInvoice({
            task_id: orderStore.getCurrentOrder?.id
        });

        localStorage.setItem(
            "invoice_created/" + orderStore.getCurrentOrder.id,
            "true",
        );
    } catch (e) {
        $toast("Failed while creating invoice. Please contact control center", TYPE.ERROR);
        throw new Error("error creating invoice");
    }
}

async function markOrderCompletedOnly() {
    try {
        orderCompletedMarked.value = await markOrderCompleted(
            orderStore.getCurrentOrder.id,
        );

        localStorage.setItem(
            "order_marked_delivered/" + orderStore.getCurrentOrder.id,
            "true",
        );
    } catch (e) {
        $toast("Error marking order as delivered", TYPE.ERROR);
        throw new Error("Error marking order delivered");
    }
}

async function addTransactionLogsOnly() {
    try {
        transactionLogCreated.value = await addTransactionLogs({
            quantity: orderStore.getDispensedQty,
            product_variation_id: filteredTankProductVarId.value[0],
            fillup_request_id: null,
            customer_order_id:
                orderStore.getCurrentOrder.customer_order?.id,
            vehicle_id: orderStore.getDriverVehicleDetails?.id,
            transaction_type: "OUT",
        });

        localStorage.setItem(
            "transaction_logs_added/" + orderStore.getCurrentOrder.id,
            "true",
        );
    } catch (e) {
        console.error("Error adding transaction logs:", e);
        $toast("Error adding transaction logs", TYPE.ERROR);
        throw new Error("Error adding transaction logs");
    }
}

const markOrderDeliverdWithFuelTrans = async () => {
    try {
        const orderId = orderStore.getCurrentOrder.customer_order?.id;
        await fuelTransKeyUpdate(orderId);
        orderCompletedMarked.value = await markOrderCompleted(orderId);
        orderStore.resetStore();
        router.replace("/dashboard");
    } catch (error) {
        $toast("Error marking order as delivered", TYPE.ERROR);
        console.error("Error marking order delivered:", error);
        throw error; // Maintain stack trace for debugging
    }
};


onMounted(async () => {
    rate.value =
        orderStore.getCurrentOrder?.customer_order?.customer_order_items[0]?.unit_price;
});
</script>

<template>
    <div class="min-h-screen">
        <div class="container mx-auto px-4 py-6 space-y-6 max-w-4xl">
            <!-- Order Card Section -->
            <OrderCard />

            <!-- Success Message Section -->
            <div class="alert alert-success shadow-lg bg-emerald-600">
                <div>
                    <div>
                        <h3 class="font-bold text-white">Order Updated Successfully!</h3>
                        <div class="text-sm text-white">Please select your preferred delivery type below to complete the order.</div>
                    </div>
                </div>
            </div>

            <!-- Delivery Type Selection -->
            <div class="card bg-base-100 shadow-xl">
                <div class="card-body">
                    <h2 class="card-title">Select Delivery Type</h2>
                    
                    <div class="space-y-4">
                        <!-- Fuel Trans Option -->
                        <div class="form-control">
                            <label class="label cursor-pointer hover:bg-base-200 rounded-box p-4">
                                <div class="flex items-center space-x-4">
                                    <input type="radio" 
                                           name="deliveryType" 
                                           class="radio radio-success checked:bg-emerald-600" 
                                           value="fueltrans"
                                           v-model="selectedDeliveryType">
                                    <div>
                                        <span class="label-text text-lg font-semibold">Fuel Trans Delivery</span>
                                        <p class="label-text-alt">Specialized fuel transportation service</p>
                                    </div>
                                </div>
                            </label>
                        </div>

                        <!-- Regular Option -->
                        <div class="form-control">
                            <label class="label cursor-pointer hover:bg-base-200 rounded-box p-4">
                                <div class="flex items-center space-x-4">
                                    <input type="radio" 
                                           name="deliveryType" 
                                           class="radio radio-succes checked:bg-emerald-600" 
                                           value="regular"
                                           v-model="selectedDeliveryType">
                                    <div>
                                        <span class="label-text text-lg font-semibold">Regular Delivery</span>
                                        <p class="label-text-alt">Standard delivery process</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Action Button -->
            <button class="btn bg-emerald-600 w-full gap-2" 
                    :class="{
                        'btn-disabled': disableButton || !selectedDeliveryType,
                        'loading': isLoading
                    }"
                    @click="handleDeliveryCompletion">
                <span v-if="!isLoading">
                    {{ selectedDeliveryType 
                        ? `Complete Order with ${selectedDeliveryType === 'fueltrans' ? 'Fuel Trans' : 'Regular'} Delivery` 
                        : 'Select Delivery Type to Continue' }}
                </span>
                <span v-else>Processing...</span>
            </button>
        </div>
    </div>
</template>

